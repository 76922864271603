import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { useAppContext } from '../../context/AppContext';
import { SupportedLocales } from '../../utils/types';

const TeaserTitle: FC = () => {
  const { language } = useAppContext();

  const text =
    language === SupportedLocales.DE
      ? 'Die grössten Hits aller Zeiten'
      : 'Les plus grands hits';

  return (
    <TeaserTitleContainer>
      <LogoWrapper>
        <img src="/img/teaser-goat-logo.svg" alt="GOAT Radio white logo" />
      </LogoWrapper>
      <TeaserTitleText>{text}</TeaserTitleText>
    </TeaserTitleContainer>
  );
};

export default TeaserTitle;

const TeaserTitleContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 12,
  padding: '12px 24px 0 24px',
  marginBottom: 16,

  [theme.breakpoints.up('gw')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: 261,
    marginBottom: 0,
    paddingLeft: 12,
    paddingRight: 0,
  },
}));

const LogoWrapper = styled('div')(() => ({
  display: 'flex',
  width: 44,
  height: 54,
  objectFit: 'cover',
  overflow: 'hidden',
  flexShrink: 0,

  '& > img': {
    width: '100%',
    height: '100%',
  },
}));

const TeaserTitleText = styled('h3')(({ theme }) => ({
  color: '#fff',
  fontSize: 24,
  fontWeight: 600,
  lineHeight: '28px',
  margin: 0,

  [theme.breakpoints.up('gw')]: {
    fontSize: 32,
    lineHeight: '38px',
  },
}));
