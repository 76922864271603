export type Language = 'de' | 'fr';

export enum SupportedLocales {
  DE = 'de',
  FR = 'fr',
}

export enum ScreenSize {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

// Temporary fix until new teaser type for radio widget is deployed
export enum TeaserSize {
  SMALLER = 'smaller',
  BIGGER = 'bigger',
}

export function isLanguage(value: unknown): value is Language {
  return value === 'de' || value === 'fr';
}

export type Platform = '20min-de' | '20min-fr' | 'lematin';

export function isPlatform(value: unknown): value is Platform {
  return value === '20min-de' || value === '20min-fr' || value === 'lematin';
}

export interface Data {
  status: number;
  message: string;
  info: string;
  response: Response;
}

export interface Response {
  serverTime: number;
  track: {
    history: TrackHistory[];
    playing: TrackCurrent;
  };
}

export interface TrackCurrent {
  id: number;
  trackSource: number;
  trackTitle: string;
  trackStart: string;
  trackEnd: string;
  trackDuration: number;
  trackDate: string;
  trackLikes: number;
  trackArtist: string;
  trackAlbum: string;
  trackAlbumCover: string;
  trackListeners: number;
  timestamp_start: number;
  timestamp_end: number;
  trackPreview: string;
  generic: boolean;
}

export interface TrackHistory {
  id: number;
  trackSource: number;
  trackTitle: string;
  trackStart: string;
  trackEnd: string;
  trackDuration: number;
  trackDate: string;
  trackArtist: string;
  trackAlbum: string;
  trackAlbumCover: string;
  trackListeners: number;
  timestamp_start: number;
  timestamp_end: number;
  trackPreview: string;
}
